

















































































































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Row,
  Col,
  Icon,
  CountDown,
} from "vant";
import MySwiper from "@/components/MySwiper.vue";
import QRCode from "qrcodejs2";
@Component({
  components: {
    MySwiper,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [CountDown.name]: CountDown,
  },
})
export default class getTicketMethods extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public ticketItemInfos!: Array<order.TicketItemRepository>; // 票信息
  @Prop({
    default: () => {
      return {};
    },
    type: Object,
  })
  public ticketInfos!: order.TicketFolderDetailDto;
  @Prop({ default: false, type: Boolean }) public admissionMode!: boolean; // 是否实名制（0否、1是）
  @Prop({ default: false, type: Boolean }) public showStatus!: false; // 场次状态
  @Prop({ default: false, type: Boolean }) public thirdPartyProject!: false; // 是否为第三方项目
  @Prop({ default: "", type: String }) public productSourceEnum!: string;
  public type = "slide";
  public activeIndex = 0;
  public orderDetailId = "";
  public pickWay = "";
  public currentQrcodeStatus = "0";
  mark = false;
  showSwiper = false;
  @Watch("ticketItemInfos")
  changeTicketItemInfos(val: Array<order.TicketItemRepository>): void {
    this.pickWay = val[0].deliveryWay!;
    this.orderDetailId = val[0].orderDetailId!;
  }
  @Watch("orderDetailId")
  changeOrderId(val: string): void {
    if (this.pickWay === "03" && this.orderDetailId) {
      this.getQRCode(this.orderDetailId);
    }
  }

  mounted(): void {
    this.showSwiper = true;
  }
  // 二维码区
  getQrCodeArea(
    deliveryWay: string,
    printStatus: boolean,
    validateStatus: boolean,
    invalidStatus: boolean
  ): { url1: string; url2: string } {
    let obj = {
      url1: "",
      url2: "",
    };
    if (deliveryWay === "03") {
      // 电子票
      if (this.currentQrcodeStatus === "1") {
        obj.url2 = require("@/assets/images/Wallet/yp2@2x.png");
      }
    } else if (deliveryWay === "02") {
      // 快递取票
      if (this.admissionMode) {
        obj.url1 = require("@/assets/images/Wallet/card-ticket.png");
      } else {
        obj.url1 = require("@/assets/images/Wallet/paper-ticket.png");
      }
    } else if (deliveryWay === "01") {
      // 现场取票
      // TODO 未打印状态展示取票二维码
      if (printStatus) {
        if (this.admissionMode) {
          obj.url1 = require("@/assets/images/Wallet/card-ticket.png");
        } else {
          obj.url1 = require("@/assets/images/Wallet/paper-ticket.png");
        }
      }
    } else if (deliveryWay === "06") {
      obj.url1 = require("@/assets/images/Wallet/card-ticket.png");
    } else if (deliveryWay === "05") {
      //临场排票
      if (this.admissionMode) {
        obj.url1 = require("@/assets/images/Wallet/card-ticket.png");
      } else {
        obj.url1 = require("@/assets/images/Wallet/paper-ticket.png");
      }
    }
    return obj;
  }
  slideChanges(swiper: any): void {
    this.activeIndex = swiper.activeIndex + 1;
    this.$emit("current", this.activeIndex);
    this.orderDetailId =
      this.ticketItemInfos[swiper.activeIndex].orderDetailId!;
    this.pickWay = this.ticketItemInfos[swiper.activeIndex].deliveryWay!;
  }
  getQRCode(id: string): void {
    this.currentQrcodeStatus = "0";
    this.$api.orderApi.ticketFolder.orderETicketQrcode(
      { orderDetailId: id },
      ({ data }) => {
        this.currentQrcodeStatus = data.qrStatus ? data.qrStatus : "";
        this.$emit("validTime", data.validBetween);
        if (data.qrCode) {
          this.$nextTick(() => {
            this.creatQrCode(data.qrCode!);
          });
        }
      }
    );
  }

  creatQrCode(id: string): void {
    (this.$refs[`qrCodeUrl${this.orderDetailId}`] as any).innerHTML = "";
    new QRCode(this.$refs[`qrCodeUrl${this.orderDetailId}`], {
      text: `${id}`, // 需要转换为二维码的内容
      colorDark: "#000000",
      colorLight: "#ffffff",
      width: 144,
      height: 144,
      correctLevel: QRCode.CorrectLevel.H,
    });
  }
  swiperSlideToSome(index: number): void {
    this.activeIndex = index;
    this.mark = true;
    this.$nextTick(() => {
      let mySwiper = this.$refs[".my-swiper"] as MySwiper;
      if (mySwiper) {
        mySwiper.initialSlide = index;
      }
    });
  }
}
